import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)

export const isValentineSeason = (date = dayjs()) => {
  const year = date.year()
  const startOfDecember = dayjs(`${year}-02-01`)
  const endOfJanuary = dayjs(`${year}-02-14`)

  // Проверяем, попадает ли дата в интервал с 1 февраля до 14 февраля
  return date.isBetween(startOfDecember, endOfJanuary, 'day', '[]')
}
