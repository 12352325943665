<script setup lang="ts">
  import { useUplatformLocale } from '~/features/locale'

  import { isHolidaySeason, isValentineSeason, useScreenSize } from './shared/lib'
  import { TheHearts, TheSnowflakes } from './widgets/layout'

  const { updateLocale } = useUplatformLocale()

  updateLocale()

  const { isTablet } = storeToRefs(useScreenSize())

  const showSnowflakes = computed(() => {
    if (isTablet.value) {
      return false
    }
    return isHolidaySeason()
  })

  const showHearts = computed(() => {
    if (isTablet.value) {
      return false
    }
    return isValentineSeason()
  })
</script>
<template>
  <div class="app-container">
    <TheSnowflakes v-if="showSnowflakes" />
    <TheHearts
      v-if="showHearts"
      :fullscreen="$route.name === 'login'"
    />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<style scoped lang="scss">
  .app-container {
    background-color: $grey;
  }
</style>

<style lang="scss">
  @include disableQuasarFormatting;
  .app-container {
    position: relative;
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scrollbar-gutter: stable;
  }
  body {
    background-color: $grey;
  }
</style>
