<script setup lang="ts">
  import { ref, onMounted } from 'vue'

  import { useScreenSize } from '~/shared/lib'
  interface Props {
    fullscreen?: boolean
  }

  const props = defineProps<Props>()

  const images = import.meta.glob('~/assets/svg/snow/*.svg', { eager: true })
  const imagePaths = Object.values(images).map((module) => (module as { default: string }).default)

  interface Snow {
    id: number
    size: number
    left?: number
    right?: number
    top: number
    duration: number
    delay: number
    imageSrc: string
  }

  const snows = ref<Snow[]>([])
  interface CreateParams {
    index: number
    isRightPart?: boolean
    isSecondWave?: boolean
  }
  const { isTablet } = storeToRefs(useScreenSize())
  const createSnow = ({ index, isRightPart = false, isSecondWave = false }: CreateParams) => {
    const config: Snow = {
      id: (index + 1) % 10,
      size: Math.random() * 20 + 20,
      top: isSecondWave ? 0 : Math.random() * 50,
      duration: Math.random() * 5 + 15,
      delay: Math.random() * 15,
      imageSrc: imagePaths[index % imagePaths.length]
    }

    if (isTablet.value) {
      config.size = Math.random() * 10 + 10
      config.left = Math.random() * 100

      return config
    }

    if (props.fullscreen) {
      config.left = Math.random() * 100
      return config
    }

    if (isRightPart) {
      config.right = Math.random() * 3
    } else {
      config.left = Math.random() * 25
    }

    return config
  }
  const createSnowfall = (isSecondWave = false) => {
    for (let i = 0; i < 40; i++) {
      const snowConfig = {
        index: i,
        isSecondWave,
        isRightPart: i >= 25
      }
      snows.value.push(createSnow(snowConfig))
    }
  }

  onMounted(() => {
    const timeout = 8_000
    createSnowfall()
    setTimeout(() => {
      createSnowfall(true)
    }, timeout)
  })
</script>

<template>
  <div class="snowfall">
    <img
      v-for="snow in snows"
      :key="snow.id"
      class="snow"
      :src="snow.imageSrc"
      :style="{
        width: `${snow.size}px`,
        height: `${snow.size}px`,
        left: snow.left ? `${snow.left}%` : 'auto',
        right: snow.right ? `${snow.right}%` : 'auto',
        top: `${snow.top}%`,
        animationDuration: `${snow.duration}s`,
        animationDelay: `${snow.delay}s`
      }"
    />
  </div>
</template>

<style lang="scss" scoped>
  .snowfall {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
  }

  .snow {
    position: absolute;
    animation: fall linear infinite;
    opacity: 0;
  }

  @keyframes fall {
    0% {
      transform: translateY(0) translateX(0);
    }
    5% {
      opacity: 1;
    }
    100% {
      transform: translateY(calc(100vh + 100px)) translateX(calc(10px - 15px * random()));
      opacity: 0;
    }
  }
</style>
